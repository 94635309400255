<template>
  <div style="background:#F5F7FF">
    <div class="common">
      <div class="common_two">
        <div class="anlie">客户案例</div>
      </div>
      <div style="display: flex;justify-content: center;">
        <div class="xaunzhe">300+标杆企业共同选择</div>
      </div>
    </div>

    <div class="main">
      <div class="num_comm">
        <div class="case_head">
          <img src="../assets/Cooperationimg/通信.png">
          <h4>数字通信</h4>
        </div>
        <div class="line">
          <hr>
        </div>
        <div class="case_content">
          <img v-for="i in 8" :key="i" :src="require('../assets/Cooperationimg/' + i + '.png')">
        </div>
      </div>

      <div class="num_country">
        <div class="case_head">
          <img src="../assets/Cooperationimg/城市.png">
          <h4>数字城市</h4>
        </div>
        <div class="line">
          <hr>
        </div>
        <div class="case_content">
          <img v-for="i in 8" :key="i" :src="require('../assets/Cooperationimg/b' + i + '.png')">
        </div>
      </div>

      <div class="num_country">
        <div class="case_head">
          <img src="../assets/Cooperationimg/政务.png">
          <h4>数字服务</h4>
        </div>
        <div class="line">
          <hr>
        </div>
        <div class="case_content">
          <img v-for="i in 4" :key="i" :src="require('../assets/Cooperationimg/c' + i + '.png')">
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.common {
  width: 100%;
  height: 570px;
  background-image: url('../assets/Cooperationimg/banner1.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .common_two{
    display: flex;
    justify-content: center;
    padding-top: 235px;
    padding-bottom: 5px;
  }
  .anlie {
    font-size: 72px;
    font-weight: bold;
    color: #FFFFFF;
  }

  .xaunzhe {
    color: #FFFFFF;
    font-size: 36px;
  }
}

.main {
  width: 1464px;
  margin: 0 auto;
  padding-bottom: 50px;

  .case_head {
    display: flex;
    align-items: flex-end;
    margin-top: 50px;

    img {
      width: 78px;
    }

    h4 {
      font-size: 42px;
      font-weight: bold;
      margin-left: 30px;
    }
  }

  .line {
    padding: 0 30px;
    margin: 20px 0;
  }

  .case_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 65px;

    img {
      width: 330px;
    }
  }
}
</style>
