var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#F5F7FF"}},[_vm._m(0),_c('div',{staticClass:"main"},[_c('div',{staticClass:"num_comm"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"case_content"},_vm._l((8),function(i){return _c('img',{key:i,attrs:{"src":require('../assets/Cooperationimg/' + i + '.png')}})}),0)]),_c('div',{staticClass:"num_country"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"case_content"},_vm._l((8),function(i){return _c('img',{key:i,attrs:{"src":require('../assets/Cooperationimg/b' + i + '.png')}})}),0)]),_c('div',{staticClass:"num_country"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"case_content"},_vm._l((4),function(i){return _c('img',{key:i,attrs:{"src":require('../assets/Cooperationimg/c' + i + '.png')}})}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common"},[_c('div',{staticClass:"common_two"},[_c('div',{staticClass:"anlie"},[_vm._v("客户案例")])]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"xaunzhe"},[_vm._v("300+标杆企业共同选择")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case_head"},[_c('img',{attrs:{"src":require("../assets/Cooperationimg/通信.png")}}),_c('h4',[_vm._v("数字通信")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case_head"},[_c('img',{attrs:{"src":require("../assets/Cooperationimg/城市.png")}}),_c('h4',[_vm._v("数字城市")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case_head"},[_c('img',{attrs:{"src":require("../assets/Cooperationimg/政务.png")}}),_c('h4',[_vm._v("数字服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('hr')])
}]

export { render, staticRenderFns }